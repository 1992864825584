var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 program-payments-report-container"},[_c('div',{staticClass:"data-table-header d-flex align-center"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"4"}},[_c('sportango-text-field',{staticClass:"mr-4",attrs:{"dense":"","name":"Sear","label":"Search Transactions","outlined":"","placeholder":"Search for transactions by name","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('programs-auto-complete',{staticClass:"mr-4",attrs:{"items":_vm.programItems,"placeholder":"Select the Program","hide-details":"","name":"program-payment-reports-selected-program"},model:{value:(_vm.selectedProgram),callback:function ($$v) {_vm.selectedProgram=$$v},expression:"selectedProgram"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[(!_vm.isCurrentUserOnlyPlayer)?_c('SportangoAutocomplete',{attrs:{"items":_vm.userItems,"placeholder":"Select the Player","hide-details":""},model:{value:(_vm.selectedPlayer),callback:function ($$v) {_vm.selectedPlayer=$$v},expression:"selectedPlayer"}}):_vm._e()],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":15,"fixed-header":"","mobile-breakpoint":"0","item-key":"itemId"},scopedSlots:_vm._u([{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_c('table-payment-method',{attrs:{"isPaymentMethodLoad":_vm.isPaymentMethodLoad(item),"item":item}})]}},{key:"item.paymentDateShort",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item ? item.paymentDateShort : "----")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && item.paymentDate)+" ")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('payment-status',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }